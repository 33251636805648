.divv {
  position: absolute;
  right: 0px;

  /* height: 100vh; */
  width: 70px;
  top: 45%;
  transform: translateY(-50%);
  z-index: 1000;
}

.icons {
  /* margin-top: 50%; */
  /* justify-content: space-betweens; */
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
}

.links:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .divv {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    z-index: 10000000000;
  }
  .icons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 30px;
    height: 100%;
  }
}
