* {
  font-family: 'Montserrat', sans-serif;
}

.main {
  position: relative;
  width: 72vw;
  height: 100vh;
  /* overflow-y: scroll;
  -ms-overflow-style: 3px;
  scrollbar-width: 3px; */

  pointer-events: auto;
  z-index: 3 !important;
  cursor: pointer;
}
.main::-webkit-scrollbar {
  display: none;
}
.mainText {
  font-size: 30px;
  font-weight: 50px;
  /* font-style: Monteserrat; */
  margin-left: 20px;
  margin-bottom: 0px;
  font-size: 1.6875rem ;
  font-size: clamp(1.25rem, 1.0541434540389973rem + 1.2534818941504178vw, 4.0625rem) !important;




}
::-webkit-scrollbar {
  width: 6px;
  /* background-color: #aaa; */
}

::-webkit-scrollbar-thumb {
  background: #b5b4b4;
}


.mainText2 {
  font-size: 30px;
  font-weight: 50px;
  /* font-style: Monteserrat; */
  margin-left: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1.6875rem ;
  font-size: clamp(1.25rem, 1.0541434540389973rem + 1.2534818941504178vw, 4.0625rem) !important;


}
.mainText3 {
  font-size: 30px;
  font-weight: 50px;
  /* font-style: Monteserrat; */
  margin-left: 20px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 1.6875rem ;
  font-size: clamp(1.25rem, 1.0541434540389973rem + 1.2534818941504178vw, 4.0625rem) !important;

}
.mainText4 {
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  font-size: clamp(1.25rem, 1.0541434540389973rem + 1.2534818941504178vw, 4.0625rem);
    font-weight: 100;



}
.mainText5 {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 800;
  margin-left: 10px !important;
  font-size: clamp(1.25rem, 1.1411908077994428rem + 0.6963788300835655vw, 2.8125rem) !important;}

.bio {
  margin-top: 40px;
  width: 58vw;
  height: 91vh;
  /* overflow-y: scroll; */
  margin-left: 180px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding-bottom: 40px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.bio2 {
  min-width: 400px;
  width: 50%;
  /* height: 50px; */
  margin-left: 20px;
  /* background: rgba(255, 255, 255, 0.002); */
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  /* backdrop-filter: blur(4px); */
  /* -webkit-backdrop-filter: blur(4px); */
  border-radius: 10px;
  border: 3px solid rgba(50, 43, 43, 0.4);
  margin-right: 25px;
  padding-right: 5px;
}

.bio3 {
  padding: 5px;

  margin-left: 10px;
}

.innerChild {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: left; */
  /* text-align: left; */
  margin-bottom: 0px;
  margin-top: 0px;
  /* padding-right: 10px  ; */
  /* padding: 0px; */
  align-items: flex-start;
}
.name {
  margin-right: 30px;
  font-size: clamp(1.25rem, 1.1803621169916434rem + 0.44568245125348194vw, 2.25rem) !important;}
  .name3 {
    margin-right: 30px;
    font-size: clamp(1.25rem, 1.1803621169916434rem + 0.44568245125348194vw, 2.25rem) !important;}
.innerChild > p {
  font-size: 12px;
}

.name2 {
  font-size: clamp(1.25rem, 1.1629526462395543rem + 0.5571030640668524vw, 2.5rem) !important;}


.bio2parent {
  display: flex;
  gap: 0px;
  /* justify-content: space-around; */
  justify-content: space-evenly;
}
button {
  background: #ffffff8e;
  /* border: none; */
  border: 1px solid rgba(128, 128, 128, 0.475);
  padding: 10px 20px;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  width: 140px;
  text-transform: uppercase;
  cursor: pointer;
  transform: skew(0deg);
  margin-left: 1.5rem;
  border-radius: 10px;
  border: 3px solid rgba(50, 43, 43, 0.4);
}

span {
  display: inline-block;
  transform: skew(0deg);
  
}

button::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  left: 0;
  background: rgb(20, 20, 20);
  opacity: 0;
  z-index: -1;
  border-radius: 5px;
  /* border: 3px solid rgba(50, 43, 43, 0.4); */
  transition: all 0.5s;
}

button:hover {
  color: #fff;
}

button:hover::before {
  left: 0;
  right: 0;
  opacity: 1;
}


@media screen and (max-width: 3850px) {
  .bio2 {
    max-height: 800px;
  }

  .name {
    width: 60%;
  }
  .name3 {
    width: 100%;
  }
  .skills {
    overflow-y: scroll;
  }
}

@media screen and (min-width:1600px) and (max-width: 1920px)  {

  .mainText{
    font-size: 30px !important;
  }
  .mainText2{
    font-size: 30px !important;
  }
  .mainText3{
    font-size: 30px !important;
  }
  .mainText4{
    font-size: 30px !important;
  }

  .mainText5{
    font-size: 20px !important;
  }

  .name{
    font-size: 18px !important;
  }
  .name3{
    font-size: 18px !important;
  }

  .name2{
    font-size: 18px !important;
    text-align: right;

  }
  .bio2 {
    width: 50%;
    max-height: 280px;
  }
  .bio2parent {
    margin-top: 15px;
  }

  .skills {
    scrollbar-width: thin;
    overflow-y: scroll;
  }
  .skills::-webkit-scrollbar {
    /* width: 5px ; */
  }
}

@media screen and (max-width: 1600px) {
  .bio {
    overflow-y: scroll !important;
  }
  .main {
    overflow-y: scroll !important;
  }
  .bio2parent {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .bio2 {
    width: 60%;
    /* max-height: 250px; */
  }

  .skills {
    overflow-y: scroll;
    scrollbar-width: 2px !important;
  }
}

@media screen and (max-width: 768px) {
  .main {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    -ms-overflow-style: 3px;
    scrollbar-width: 3px;
    /* background-color: red; */

    cursor: pointer;
  }
  .bio {
    margin-top: 80px;
    width: 90vw;
    height: 80vh;
    padding-right: 1.2rem;
    /* overflow-y: scroll; */
    margin-left: 1rem;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding-bottom: 40px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 20px;

    overflow-y: scroll;
    overflow-x: hidden;
  }

  .bio2parent {
    display: flex;
    flex-direction: column;
  }
  .bio2 {
    padding: 1px;
    margin-left: 5px;
    min-width: 100%;
    margin-bottom: 1rem;
    max-height: 345px;
  }
  .bio2parent {
    display: flex;
    gap: 1rem;
  }


  .name {
    width: 70%;
    font-size: 15px !important;
  }
  .name3{
    width: 100%;
    font-size: 15px !important;

  }
  .name2 {
    text-align: right;
    font-size: 15px !important;
  }
  .bio3 {
    padding: 3px;
  }
  button{
    margin-left: 5px;
  }
}
