@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #f0f0f0;
  color: black;
  font-family: 'Inter';
}

a {
  color: black;
}

a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}

svg {
  fill: black;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 1s forwards;
}


.Heading{
  top: 14%;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'DM Sans', sans-serif;
font-family: 'Montserrat', sans-serif;
  position: absolute;
  font-size:5rem;
  color: rgb(61, 58, 58);
}


/* Apply these styles only to screens with a maximum width of 768px and in landscape orientation */
@media screen and (max-width: 768px) and (orientation: landscape) {
  body {
    transform: rotate(-90deg);
    transform-origin: top left;
    width: 100vh;
    height: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

