.hud {
  /* background-color: red; */
  position: absolute;
  top: 90%;
  transform: translateY(-50%);
  width: 100vw;
  z-index: 1000000000000;
}
.arrows {
  display: flex;
  justify-content: space-between;
}

.arrows img {
  height: 5rem;
  -webkit-filter: invert(1);
  filter: invert(1);
}
.right {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
}
.left {
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-60%);
}
.arrows img {
  cursor: pointer;
}
.vid1{
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
.vid {
  position: absolute;
  z-index: 4;
  top: 45%;
  left: 19%;
  opacity: 0;
  /* animation-name: playeer ;
    animation-delay: 4.3s;
    animation-duration:infinite; */
  animation: playeer 2s ease-in ;
  animation-fill-mode: forwards;
  
}

.grey {
  position: absolute;
  top: 0;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.307), rgb(0, 0, 0));
  
   /* background-color: black; */
  height: 100vh; /* Ensure the container takes up the full viewport height */
  background-size: cover; /* Cover the entire screen */
  width: 100vw;
  z-index: 3;
}


button {
  background: #fff;
  border: none;
  padding: 10px 20px;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  width: 140px;
  text-transform: uppercase;
  cursor: pointer;
  transform: skew(-21deg);
}

span {
  display: inline-block;
  transform: skew(21deg);
}

button::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  left: 0;
  background: rgb(20, 20, 20);
  opacity: 0;
  z-index: -1;
  transition: all 0.5s;
}

button:hover {
  color: #fff;
}

button:hover::before {
  left: 0;
  right: 0;
  opacity: 1;
}


@keyframes playeer {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.player {
  width: 55%;
}

.close{
  position: absolute;
  top: 3%;
  right: 2%;
  width: 3rem;
  -webkit-filter: invert(1);
  filter: invert(1);
}
.image1{
  width: 3rem;

}
.image1:hover{
  width: 3rem;
  cursor: pointer;
}
@media screen and (max-width:1920px) {
  .player {
    width: 60rem;
  }
  .vid{
    top: 36%;
    left: 22%;
  }
  
.image{
  position: absolute;
  left: 29%;
  top: 3%;
  width: 35rem;
  /* height: 100%; */
  z-index: 3;
  text-decoration:none;
	/* border:#CCCCCC thin solid;
	padding: 0px;
  border-radius: 10px; */
}
  .but{
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 12%;
      top: 85%;
      gap:0.6rem;
  }

  .demo{
    width: 12rem;
    height: 3rem;
    font-size: 20px;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
  .Github{
    width: 12rem;
    height: 3rem;
    font-size: 20px;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
}

@media screen and (max-width:1600px) {
  .player {
    width: 50rem;
  }
  .vid{
    width: 50rem !important;
  }
  .image{
    position: absolute;
    left: 26%;
    top: 2%;
    width: 31rem;
    /* height: 100%; */
    z-index: 3;
    text-decoration:none;
    /* border:#CCCCCC thin solid;
    padding: 0px;
    border-radius: 10px; */
  }
  .demo{
    width: 7rem;
    height: 2.5rem;
    font-size: 12px;
    border-radius: 1rem;
    /* background: rgba(255, 255, 255, 0.25); */
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
  .Github{
    width: 7rem;
    height: 2.5rem;
    font-size: 12px;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
}



@media screen and (max-width: 768px) {
  .hud {
    top: 83%;
  }
  .player {
    width: 100vw;
  }

  .image{
    position: absolute;
    /* left: 15%; */
    top: 23%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    /* height: 100%; */
    z-index: 3;
    text-decoration:none;
    /* border:#CCCCCC thin solid;
    padding: 0px;
    border-radius: 10px; */
  }
  .vid{

    left: 0;
    top: 50%;
  }
  .hud{
    top: 95%;
  }

  .arrows img {
    height: 3rem;
    -webkit-filter: invert(1);
    filter: invert(1);
  }
  .but{
    top: 86.5%;;
    z-index: 10 !important;
    left: 50%;
    transform: translateX(-50%);
  }
  .demo{
    height: 3rem;
    z-index: 100 !important;
  }
  
  .Github{
    height: 3rem;
    z-index: 100 !important;
  }
  .image1{
    width: 2rem;
  
  }

}