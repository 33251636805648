/* App.css */


  
  .loading-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border: 5px solid rgb(255, 255, 255); /* Circle border color */
    border-radius: 50%;
    background-color:rgb(255, 255, 255);
  }
  
  .loading-text {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 24px;
    color: #3498db; /* Text color */
  }
  
.main{
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: black;
    
}
.main::-webkit-scrollbar{
    width: 0px;
}



  .inverse-text {
    position: absolute;
    font-size: 7rem;
    font-weight: 300;
    color: white; /* Set the initial text color (fallback color) */
    padding: 10px; /* Add some padding to the text to make it more visible */
    border-radius: 5px; /* Add rounded corners to the background */
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    /* Invert the text color compared to the background */
    mix-blend-mode: difference;
  }

  .main2{


    position: absolute;
    top:0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: white;
    z-index: 5;
    text-align: center;
  }
  .text1{
    position: absolute;
    width: 100%;
    font-size: 6rem;
    font-weight: lighter;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    animation: fromBot 4s ease both;
  }

  @keyframes fromBot{
      0%{
        opacity: 0;
        top: 60%;
      }
      50%{
        opacity: 1;
        top: 50%;
      }
      100%{
        opacity: 0;
        top: 30%
      }
  }

  @media screen and (max-width:768px) {
    .inverse-text{
        font-size: 4rem;
    }
    .text1{
      font-size: 4rem;
    }
  }