.main{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100vw;
    z-index: 1000000000000;   
    display: none;
}

.arrows{
    display: flex;
    justify-content: space-between;
}

.arrows img{
    height: 3rem;
}
.right{
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);


}
.left{
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);


}
.arrows img {
    cursor: pointer;
  }

  @media screen and (max-width:768px) {
    .main{
        display: block;
        padding-left: 0.8rem;
    }
    .gradient {
        
        width: 2.5rem;
        height: 10rem;
        --borderWidth: 3px;
        background: rgb(210, 206, 195);
        position: relative;
        border-radius: var(--borderWidth);
      }
      .gradient:after {
        content: '';
        position: absolute;
        top: calc(-1 * var(--borderWidth));
        left: calc(-1 * var(--borderWidth));
        height: calc(100% + var(--borderWidth) * 2);
        width: calc(100% + var(--borderWidth) * 2);
        background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
        border-radius: calc(2 * var(--borderWidth));
        z-index: -1;
        animation: animatedgradient 3s ease alternate infinite;
        background-size: 300% 300%;
      }
      
      
      @keyframes animatedgradient {
          0% {
              background-position: 0% 50%;
          }
          50% {
              background-position: 100% 50%;
          }
          100% {
              background-position: 0% 50%;
          }
      }
      
      
}